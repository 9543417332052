import React, { Component } from 'react';
import { Icon, Message, Tab } from 'semantic-ui-react';
import ShippingOptions from './ShippingOptions';
import './styles.css';
import {
  setShippingType, SHIPPING_METHOD_CDEK,
  SHIPPING_METHOD_DELLIN,
  SHIPPING_METHOD_OWN, SHIPPING_METHOD_OZON,
  SHIPPING_TYPE_DELIVERING, SHIPPING_TYPE_LOCAL_PICKUP
} from './actions';

import PropTypes from 'prop-types';
import {
  ADDRESS_FULLADDRESS,
  ADDRESS_GEOLAT,
  ADDRESS_GEOLON,
  ADDRESS_STATE
} from '../AddressCard/actions';
import { addressField } from '../AddressCard/reducer';
import { getCart } from '../../views/Cart/reducer';
import { getOption, getShippingMethod, getShippingOptionFetching, getShippingType } from './reducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class ShippingTypes extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e, data) => {
    this.setState(data);
    const { dispatch } = this.props;
    dispatch(
        setShippingType(
            data.activeIndex === 0 ?
                SHIPPING_TYPE_DELIVERING : SHIPPING_TYPE_LOCAL_PICKUP
        ));
    //this.readShippingOptions( value );
  };

  panes = [
    {
      menuItem: 'Доставка до адреса',
      render: () => (
          <Tab.Pane attached={false} >
            <ShippingOptions
                isFetching = { this.props.isFetching }
                address = { this.props.address }
                cart = { this.props.cart }
                shippingType = { this.props.shippingType }
                shippingMethod = { this.props.shippingMethod }
                shippingOptions = { this.props.shippingOptions }
            />
            <Message icon>
              { this.props.isFetching ?
                  <Icon name='circle notched' loading/> : <Icon name='shipping'/> }
              <Message.Content>
                { this.props.address.fullAddress }
              </Message.Content>
            </Message>
          </Tab.Pane>
      ),
    },
    {
      menuItem: 'Самовывоз',
      render: () => (
          <Tab.Pane attached={false}>
            <ShippingOptions
                isFetching = { this.props.isFetching }
                address = { this.props.address }
                cart = { this.props.cart }
                shippingType = { this.props.shippingType }
                shippingMethod = { this.props.shippingMethod }
                shippingOptions = { this.props.shippingOptions }
            />
            <Message icon>
              { this.props.isFetching ?
                  <Icon name='circle notched' loading /> : <Icon name='map' /> }
              <Message.Content>
                &nbsp;<a href='#'>Открыть карту</a>&nbsp;для выбора ближайшего пункта выдачи заказов
              </Message.Content>
            </Message>
          </Tab.Pane>
      ),
    },
  ];

  render() {
    return (
        <Tab
            menu={{ color: 'blue', secondary: true, pointing: true }}
            panes={this.panes}
            onTabChange={this.handleChange}
            defaultActiveIndex={ this.props.shippingType === SHIPPING_TYPE_DELIVERING ? 0 : 1 }
        />
    );
  }
}

ShippingOptions.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFetching: getShippingOptionFetching(state.shipping),
  address: {
    [ADDRESS_FULLADDRESS]: addressField(state.address, ADDRESS_FULLADDRESS),
    [ADDRESS_STATE]: addressField(state.address, ADDRESS_STATE),
    [ADDRESS_GEOLON]: addressField(state.address, ADDRESS_GEOLON),
    [ADDRESS_GEOLAT]: addressField(state.address, ADDRESS_GEOLAT),
  },
  cart: getCart(state.cart).map(
      obj=>({productId: obj.id, variationId: obj.variationId, quantity: obj.quantity})
  ),
  shippingType: getShippingType(state.shipping),
  shippingMethod: getShippingMethod(state.shipping),
  shippingOptions: {
    [SHIPPING_METHOD_OWN.key]:
        getOption(state.shipping, SHIPPING_METHOD_OWN.key, getShippingType(state.shipping),
            addressField(state.address, ADDRESS_FULLADDRESS)),
    [SHIPPING_METHOD_DELLIN.key]:
        getOption(state.shipping, SHIPPING_METHOD_DELLIN.key, getShippingType(state.shipping),
            addressField(state.address, ADDRESS_FULLADDRESS)),
    [SHIPPING_METHOD_CDEK.key]:
        getOption(state.shipping, SHIPPING_METHOD_CDEK.key, getShippingType(state.shipping),
            addressField(state.address, ADDRESS_FULLADDRESS)),
    [SHIPPING_METHOD_OZON.key]:
        getOption(state.shipping, SHIPPING_METHOD_OZON.key, getShippingType(state.shipping),
            addressField(state.address, ADDRESS_FULLADDRESS)),
  },
});

function mapDispatchToProps(dispatch) {
  return Object.assign(
      { dispatch },
      bindActionCreators({
        setShippingType,
      }, dispatch),
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingTypes);
