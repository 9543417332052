import React from 'react';
import { Header } from 'semantic-ui-react';
import GeoLocation from './GeoLocation/GeoLocation';
import MiniOrderCard from "./OrderCard/MiniOrderCard";
import _ from "lodash";

export default function OrdersList(props) {

  const { title, orders } = props;
  const list = orders.map((element) => {
      if (_.isEmpty(element)) return;
      return ( <MiniOrderCard
          key={element.id}
          order={element}
          header={true}
          circular={true}
          actionButtons={false}
      />);
  });

  return (
      <div>
        <GeoLocation />
        <Header textAlign="center">{title}</Header>
        {list}
      </div>
    );
}
