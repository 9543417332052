import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Loader, Container, Dimmer} from 'semantic-ui-react';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { fetchProducts } from '../Products/actions';
import {
  getProductsFetching, getProducts, productPropType, getProductsHasMore,
} from '../Products/reducer';
import ProductsList from '../../components/ProductsList';
import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.loadProducts = this.loadProducts.bind(this);
  }

  componentDidMount() {
    if (this.props.searchVisible) {
      this.props.closeSearch();
    }
    window.scrollTo(0, 0);
    this.readProducts(1);
  }

  /**
   * Filter and return featured products (if there are any)
   */
  getFilteredProducts() {
    const items = this.props.products.filter((product) => product.featured);
    if (items.length > 0) {
      return items;
    }

    return this.props.products;
  }

  loadProducts() {
    if (this.props.hasMore) {
      const items = this.getFilteredProducts();

      // 20 is the default per_page number used for paginating products
      const nextPage = Math.round(items.length / 20) + 1;
      this.readProducts(nextPage);
    }
  }

  readProducts(page) {
    const { dispatch } = this.props;
    dispatch(fetchProducts({
      expo_services: 'yes',
      page,
      featured: 1,
      order: 'asc',
      orderby: 'title',
      per_page: 20,
    }));
  }

  render() {
    const { loading, products, hasMore } = this.props;

    if (loading === 1 && products.length === 0) {
      return (
          <Dimmer active={ loading } inverted>
            <Loader>Загружаем продукты ...</Loader>
          </Dimmer>
      );
    }

    if (products.length === 0) {
      return (
          <Container>
            <p>Продукты не найдены</p>
          </Container>
      );
    }

    // Filter featured products (if there are any)
    const items = this.getFilteredProducts();

    return (
        <InfiniteScroll
            dataLength={items.length}
            next={this.loadProducts}
            hasMore={hasMore}
            loader={<h3 className="scroll-loader-wrapper">Загрузка ...</h3>}
            endMessage={(
                <p style={{ textAlign: 'center' }}>
                  <b>Конец списка</b>
                </p>
            )}
        >
          <ProductsList products={_.orderBy(items, ['name'], ['asc'])} title="Билеты на мероприятия" />
        </InfiniteScroll>
    );
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(productPropType).isRequired,
  hasMore: PropTypes.bool.isRequired,
  searchVisible: PropTypes.bool.isRequired,
  closeSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: getProductsFetching(state.products),
  products: getProducts(state.products),
  hasMore: getProductsHasMore(state.products),
  searchVisible: isSearchVisible(state.navtopbar),
});

function mapDispatchToProps(dispatch) {
  return { dispatch, ...bindActionCreators({ fetchProducts, closeSearch }, dispatch) };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home);