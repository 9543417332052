import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Container, Header } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

import {fetchOrders, fetchProducts, resetSearchOrders, resetSearchProducts} from './actions';
import {getSearchFetching, getSearch, getHasMore} from './reducer';
import ProductsList from '../../components/ProductsList';

import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import {useLocation, useParams} from 'react-router';
import {getAuthToken, getUserData} from "../../components/UserLogin/reducer";
import OrdersList from "../../components/OrdersList";
import {ORDERS_PAGE_COUNT} from "../Orders/actions";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";

export default function Search(props) {

  const { what } = props;

  const matchParams = useParams();
  const search = matchParams.search;
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const loading = useSelector((state) => getSearchFetching(state.search));
  const searchItems = useSelector((state) => getSearch(state.search));
  const hasMore = useSelector((state) => getHasMore(state.search));
  const dispatch = useDispatch();
  const [stateSearch, setStateSearch] = useState({page: 1});
  const token  = useSelector((state) => getAuthToken(state.userLogin));

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();

  const { state } = useLocation();

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    resetSearchProducts();
    resetSearchOrders();

    if ( typeof state !== 'undefined' && state.doQuery ) {
      what === 'products' ? readProducts(1) : readOrders(1);
      window.scrollTo(0, 0);
    }

    if (mixpanel)
      mixpanel.track('Search List View', { ...currentExpoLocation, ...userLocation, ...userData, search });

  }, [search]);

  const loadMore = () => {
    if (hasMore) {
      what === 'products' ? readProducts(stateSearch.page + 1) : readOrders(stateSearch.page + 1);
    }
  };

  const readProducts = (page)  => {
    dispatch(fetchProducts({
          search,
          page,
          order: 'asc',
          orderby: 'id',
        }));
    setStateSearch({page})
  }

  const readOrders = (page)  => {
    dispatch(fetchOrders(token, {
      search,
      page,
      per_page: ORDERS_PAGE_COUNT,
      order: 'desc',
      orderby: 'id',
    }));
    setStateSearch({ page });
  };

  if (loading === 1 && searchItems.length === 0) {
    return (<div><Loader active /></div>);
  }

  if (searchItems.length === 0) {

    if (!navigator.onLine) {
      return (<Container><p>Нет подключения к Интернету</p></Container>);
    }

    return (
      <Container>
        <Header textAlign="center">
          {`Результаты поиска '${search}'`}
        </Header>
        <p>Ничего не найдено.</p>
      </Container>
    );
  }

  const items = _.orderBy(searchItems, ['name'], ['asc']);

  return (
    <InfiniteScroll
      dataLength={items.length}
      next={loadMore}
      hasMore={hasMore}
      loader={<h3 className="scroll-loader-wrapper">Загрузка ...</h3>}
      endMessage={(
          <p style={{ textAlign: 'center' }}>
            <b>Поиск завершен</b>
          </p>
      )}
    >
      { what === 'products'
          ? <ProductsList products={items} title={`Результаты поиска '${search}'`} />
          : <OrdersList orders={items} title={`Результаты поиска '${search}'`} />
      }
    </InfiniteScroll>
  );

}



