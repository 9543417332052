// file = Html5QrcodePlugin.jsx
import { Html5QrcodeScanner } from 'html5-qrcode';
import {createElement, useEffect, useState} from 'react';

const Html5QrcodePlugin = (props) => {

    const qrcodeRegionId = "html5qr-code-full-region";

    // Creates the configuration object for Html5QrcodeScanner.
    const createConfig = (props) => {
        let config = {};
        if (props.fps) {
            config.fps = props.fps;
        }
        if (props.qrbox) {
            config.qrbox = props.qrbox;
        } else
            config.qrbox = qrboxFunction;

        if (props.aspectRatio) {
            config.aspectRatio = props.aspectRatio;
        }
        if (props.disableFlip !== undefined) {
            config.disableFlip = props.disableFlip;
        }
        return config;
    };

    const qrboxFunction = function(viewfinderWidth, viewfinderHeight) {
        const width = Math.floor(viewfinderWidth * 0.7);
        const height = Math.floor(viewfinderHeight * 0.9);
        document.documentElement.style
            .setProperty('--left-start-pos', String(-Math.floor(width*0.9/2)) + 'px');
        document.documentElement.style
            .setProperty('--right-stop-pos', String(Math.floor(width*0.9/2)) + 'px');
        return {
            width,
            height,
        };
    }

    useEffect(() => {
        // when component mounts
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Success callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        props.forwardRef.current = html5QrcodeScanner;

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    return (
        <div className="qr-scan-frame">
            <div id={qrcodeRegionId}></div>
        </div>
    );
};

export default Html5QrcodePlugin;