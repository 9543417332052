import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLocationData } from './reducer';
import { Icon, Label, Message } from 'semantic-ui-react';
import { requestCoordinates } from './actions';

class GeoLocation extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestCoordinates());
  }

  render() {
    const { userLocation } = this.props;
    return <Message icon className="task-geolocation-message">
      <Icon name="location arrow" />
      <Message.Content>
        <Message.Header>Местоположение</Message.Header>
            {
            userLocation.address ? userLocation.address :
              'Широта: ' + String(userLocation.latitude) + ', Долгота: ' + String(userLocation.longitude)
            }
      </Message.Content>
      <Label attached="top right">{'точность: ' + String(userLocation.accuracy|0)  + ' м'}</Label>
    </Message>;
  }
}

const mapStateToProps = (state) => ({
  userLocation: getLocationData(state.userLocation),
});

function mapDispatchToProps(dispatch) {
  return { dispatch, ...bindActionCreators({ requestCoordinates }, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeoLocation);
