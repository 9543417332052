import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Modal, Button, Header, Form, Message,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { store } from '../../configureStore';
import { isAuthVisible } from '../NavTopBar/reducer';
import { closeAuth } from '../NavTopBar/actions';
import { fetchToken } from './actions';
import './UserLogin.css';
import { getUserLoggedIn, getUserLoginError, getUserTokenFetching } from './reducer';

class UserLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screen: 0,
      phone: '',
      email: '',
      password: '',
      phoneError: false,
      emailError: false,
      passwordError: false,
      formError: false,
      errorMessage: 'Обязательные поля должны быть заполнены',
      complete: false,
    };

    this.submitForm = this.submitForm.bind(this);
    this.successCallback = this.successCallback.bind(this);
  }

  successCallback() {
    this.setState({
      complete: true,
    });
    const { closeAuth: close } = this.props;
    close();
  }

  submitForm() {
    let error = false;
    const { dispatch } = this.props;
    const {
      email,
      password,
      phone,
    } = this.state;

    if (phone === '' && email === '' && password === '') {
      this.setState({ emailError: true });
      this.setState({ passwordError: true });
      error = true;
    } else if (phone === '' && email !== '' && password === '') {
      this.setState({ passwordError: true });
      error = true;
    } else if (phone === '' && email === '' && password !== '') {
      this.setState({ emailError: true });
      error = true;
    } else {
      error = false;
      this.setState({ phonedError: false });
      this.setState({ emailError: false });
      this.setState({ passwordError: false });
    }

    if (error) {
      this.setState({ formError: true });
      return;
    }

    this.setState({ formError: false });

    dispatch(fetchToken(email, password));
  }

  render() {
    const {
      closeAuth: close, showModal, isFetching, messageLoginError,
    } = this.props;
    const {
      screen, formError, phoneError, emailError, passwordError,
    } = this.state;

    return (
      <Modal
        dimmer
        centered={true}
        open={showModal}
        onClose={close}
        closeOnDimmerClick
        className="user-login"
      >
        <Header icon="sign in" content="Войдите или зарегистрируйтесь" />
        <Modal.Content>
          { screen === 0 ? (
            <Modal.Description>
              { messageLoginError !== '' ? (
                <Message
                  negative
                  icon="user times"
                  header="Вы не авторизованы"
                  content={messageLoginError}
                />
              ) : null }
              <Form error={formError} onSubmit={this.submitForm} loading={!!isFetching}>
                {
                  /*Введите номер телефона, мы отправим вам одноразовый пароль
                <Form.Field>
                  <Form.Input
                    icon="phone"
                    iconPosition="left"
                    placeholder="Телефон"
                    fluid
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    error={phoneError}
                  />
                </Form.Field>
                ИЛИ
                <br />*/
                }
                <Form.Field>
                  <Form.Input
                    fluid
                    icon="user"
                    iconPosition="left"
                    placeholder="Логин или электронная почта"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    error={emailError}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    fluid
                    icon="key"
                    iconPosition="left"
                    placeholder="Пароль"
                    onChange={(e) => this.setState({ password: e.target.value })}
                    error={passwordError}
                  />
                </Form.Field>
              </Form>
            </Modal.Description>
          ) : null }
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={close}>Отмена</Button>
          <Button positive onClick={this.submitForm}>Войти</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

UserLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  closeAuth: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  messageLoginError: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  showModal: isAuthVisible(state.navtopbar),
  isFetching: getUserTokenFetching(state.userLogin),
  isLoggedIn: getUserLoggedIn(state.userLogin),
  messageLoginError: getUserLoginError(state.userLogin),
});

function mapDispatchToProps(dispatch) {
  return { dispatch, ...bindActionCreators({ closeAuth }, dispatch) };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);
