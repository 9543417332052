import config, {AUTH_INVALID_TOKEN} from "../../config/config";
import {dropToken} from "../UserLogin/actions";
import {toastr} from "react-redux-toastr";

export const REQUEST_EXPO_LOCATION_DATA = 'REQUEST_EXPO_LOCATION_DATA';
export const RECEIVE_EXPO_LOCATION_DATA = 'RECEIVE_EXPO_LOCATION_DATA';
export const SET_EXPO_LOCATION_DATA = 'SET_EXPO_LOCATION_DATA';

export const requestExpoLocationData = () => ({
  type: REQUEST_EXPO_LOCATION_DATA,
});

export const receiveExpoLocationData = (data) => ({
    type: RECEIVE_EXPO_LOCATION_DATA,
    data,
});
export const setExpoLocationData = (data) => ({
  type: SET_EXPO_LOCATION_DATA,
  data,
});

const process_expo_location_api_error = (json, dispatch) => {
        if (json.code === AUTH_INVALID_TOKEN) {
            dispatch(dropToken());
        }
        dispatch(receiveExpoLocationData([]));
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchExpoLocationData = (token) => (dispatch) => {
        dispatch(requestExpoLocationData());

        const headers = { Authorization: 'Bearer ' + token };

        return fetch(config.API_EXPO_LOCATIONS_URL, { headers })
            .then((response) => response.json())
            .then((json) => {
                if (json.code === 200)
                    dispatch(receiveExpoLocationData(json.data));
                else
                    process_expo_location_api_error(json, dispatch);
            })
            .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
    };


