import config, {AUTH_INVALID_TOKEN} from "../../config/config";
import {dropToken} from "../UserLogin/actions";
import {toastr} from "react-redux-toastr";

export const SET_ADDRESS_FIELDS = 'SET_ADDRESS_FIELDS';
export const ADDRESS_FIRSTNAME = 'firstName';
export const ADDRESS_LASTNAME = 'lastName';
export const ADDRESS_COMPANY = 'companyName';
export const ADDRESS_ADDRESS = 'firstAddress';
export const ADDRESS_ADDINFO = 'secondAddress';
export const ADDRESS_COUNTRY = 'countryCode';
export const ADDRESS_POSTCODE = 'postCode';
export const ADDRESS_STATE = 'stateCode';
export const ADDRESS_CITY = 'cityName';
export const ADDRESS_CITY_KLADR = 'cityKLadr';
export const ADDRESS_PHONE_COUNTRY = 'countryPhoneCode';
export const ADDRESS_PHONE = 'phone';
export const ADDRESS_EMAIL = 'email';
export const ADDRESS_FULLADDRESS = 'fullAddress';
export const ADDRESS_GEOLON = 'geoLon';
export const ADDRESS_GEOLAT = 'geoLat';
export const ADDRESS_COMPANY_INN = 'companyInn';
export const ADDRESS_COMPANY_KPP = 'companyKpp';
export const ADDRESS_COMPANY_OGRN = 'companyOgrn';
export const ADDRESS_COMPANY_ADDR = 'companyAddress';
export const ADDRESS_COMPANY_JOB_TITLE = 'companyJobTitle';
export const ADDRESS_COMPANY_SCOPE = 'companyScope';

export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES';
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';

export const requestCountries = () => ({
  type: REQUEST_COUNTRIES,
});

export const receiveCountries = (data) => ({
  type: RECEIVE_COUNTRIES,
  data,
});

const process_countries_api_error = (json, dispatch) => {
  dispatch(receiveCountries([]));
  toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchCounties = () => (dispatch) => {
  dispatch(requestCountries());

  return fetch(config.API_COUNTRIES_URL)
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 200)
          dispatch(receiveCountries(json.data));
        else
          process_countries_api_error(json, dispatch);
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const setAddressFields = (data) => ({
  type: SET_ADDRESS_FIELDS,
  data,
});

