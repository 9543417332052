import React, { useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import { Segment, Header, Button } from 'semantic-ui-react';
import { getCart, getTotalPrice } from './reducer';
import CartProduct from './CartProduct';
import CardSummary from '../../components/CardSummary/CardSummary';
import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import {getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";

export default function Cart(props) {

  const dispatch = useDispatch();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const cart = useSelector((state) => getCart(state.cart));
  const total_price = useSelector((state) => getTotalPrice(state.cart));

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    if (mixpanel)
      mixpanel.track('Cart View', { ...currentExpoLocation, ...userLocation, ...userData, cart });
    window.scrollTo(0, 0);
  }, []);


  return _.isEmpty(cart) ? (
      <Segment textAlign="center">Ваша корзина пока еще пуста</Segment>
    ) : (
      <div>
        <Header textAlign="center">Корзина</Header>
        {cart.map((product) => (
          <CartProduct
            key={_.isNil(product.variationId) ? product.id : product.variationId}
            product={product}
            show_buttons={true}
          />
        ))}
        <CardSummary total_products={total_price} />
        <Link to= { cart.length <= config.MAX_PRODUCTS_IN_CART ? "/checkout-step-shipping" : "#" }>
          <Button
              className="goto-checkout"
              color="green"
              fluid
              disabled={ cart.length > config.MAX_PRODUCTS_IN_CART }
          >
            Оформить заказ
          </Button>
        </Link>
      </div>
    );
}
