import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Header, Loader, Container,
} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';

import { fetchCategories, PAGE_COUNT } from './actions';
import {
  getCategories, getCategoriesFetching, getCategoriesHasMore,
} from './reducer';
import CategoriesList from './CategoriesList';
import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { useParams } from 'react-router';

export default function Categories(props) {

  const matchParams = useParams();
  const categId = matchParams.categId;
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const dispatch = useDispatch();
  const hasMore = useSelector((state) => getCategoriesHasMore(state.categories));
  const loading = useSelector((state) => getCategoriesFetching(state.categories));
  const categories = useSelector((state) => getCategories(state.categories));

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    readCategories(1);
  }, [categId]);

  const readCategories = (page) => {
    dispatch(fetchCategories({
      parent: categId,
      page,
      per_page: PAGE_COUNT,
    }));
  };

  const loadCategories = () => {
    if (hasMore) {
      const nextPage = Math.round(categories.length / PAGE_COUNT) + 1;
      readCategories(nextPage);
    }
  };

  if (loading === 1 && categories.length === 0) {
    return (<div><Loader active /></div>);
  }

  if (categories.length === 0) {
    return (<Container><p>Ничего не найдено</p></Container>);
  }

  const items = _.orderBy(categories, ['name'], ['asc']);

  return (
    <div>
      <Header textAlign="center">Категории</Header>
      <InfiniteScroll
        dataLength={items.length}
        next={loadCategories}
        hasMore={hasMore}
        loader={<h3 className="scroll-loader-wrapper">Загрузка ...</h3>}
        endMessage={(
            <p style={{ textAlign: 'center' }}>
              <b>Конец списка</b>
            </p>
        )}
      >
        <CategoriesList categories={items} />
      </InfiniteScroll>
    </div>
  );
}



