import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Card, Divider, Header, Icon, Loader, Button,
    Segment, Modal, Dimmer, Image, Input, Grid,
} from 'semantic-ui-react';

import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {Link} from "react-router-dom";

import './styles.css';
import {getAddressFields, getCountries, isCountriesFetching} from "../AddressCard/reducer";
import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS,
    ADDRESS_CITY,
    ADDRESS_COMPANY,
    ADDRESS_COMPANY_ADDR,
    ADDRESS_COMPANY_JOB_TITLE,
    ADDRESS_COMPANY_SCOPE,
    ADDRESS_COUNTRY,
    ADDRESS_EMAIL,
    ADDRESS_FIRSTNAME, ADDRESS_GEOLAT, ADDRESS_GEOLON,
    ADDRESS_LASTNAME,
    ADDRESS_PHONE,
    ADDRESS_POSTCODE,
    ADDRESS_STATE,
    fetchCounties,
    setAddressFields
} from "../AddressCard/actions";
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import OrderCardHeader from "./OrderCardHeader";
import AddressCard from "../AddressCard";
import {createOrderQRCode, receiveOrderData, updateOrder, uploadOrderImage} from "../../views/Orders/actions";
import {getLocationData} from "../GeoLocation/reducer";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import Camera, {FACING_MODES} from "react-html5-camera-photo";
import {toastr} from "react-redux-toastr";
import {BadgeToPrint} from "../PrintComponent/PrintComponent";
import {useReactToPrint} from "react-to-print";
import ModalPrompt from "../ModalPrompt";
import {useMixpanel} from "react-mixpanel-browser";

import { store } from '../../configureStore';

export default function MiniOrderCard(props) {

    const { order, circular, header, actionButtons } = props;
    const { billing, id } = order;

    const dispatch = useDispatch();
    const token  = useSelector((state) => getAuthToken(state.userLogin));
    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));
    const getLoadingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));
    const fields = useSelector((state) => getAddressFields(state.address));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();

    const [openPromptModal, setOpenPromptModal] = React.useState(false);
    const [openBadgeModal, setOpenBadgeModal] = useState(false);
    const [openAddressModal, setOpenAddressModal] = useState(false);
    const [statePhoto, setStatePhoto] = useState({
        hasOpen: false,
        hasLoaded: false,
        dataUri: '',
        mode: FACING_MODES.ENVIRONMENT,
        tag: '',
    });

    const country = typeof countries === 'object' && typeof countries.countries === 'object'
        ? countries.countries[billing.country] ?? {} : {};
    const countryStates = typeof country === 'object' && country.states
        ? country.states.filter( item => String(item.code) === billing.state ) : [];

    useEffect(() => {
        if (typeof country.iso === 'undefined' && !getLoadingCountries) {
            console.warn('Mini order card has queried countries ...');
            dispatch(fetchCounties());
        }
        dispatch(receiveOrderData([]));
    }, []);

    const orderCountry = country.iso ? country.emoji + ' ' + country.country_ru : billing.country;
    const orderState = countryStates.length ? countryStates[0].name : `регион ${billing.state}`;
    const orderAddress = billing.city === billing.address_1 ? '' : billing.address_1;

    //console.log('global redux states', store.getState());
    //console.log('COUNTRY DATA', billing, countries, country, countryStates, getLoadingCountries, orderCountry, orderState);

    const OrderImage = () => (
        <Image src={order.file_image === '' ? "https://react.semantic-ui.com/images/wireframe/square-image.png" : order.file_image}
               size="medium"
               circular={ order.file_image ? false : circular}
               className="order-image"
        />
    );

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    function openModalAddress() {

        const orderFields = {
            [ADDRESS_FIRSTNAME]: billing.first_name,
            [ADDRESS_LASTNAME]: billing. last_name,
            [ADDRESS_COMPANY]: billing.company,
            [ADDRESS_COMPANY_JOB_TITLE]: billing.company_job_title,
            [ADDRESS_COMPANY_SCOPE]: billing.company_scope,
            [ADDRESS_ADDRESS]: billing.address_1,
            [ADDRESS_ADDINFO]: billing.address_2,
            [ADDRESS_COUNTRY]: billing.country,
            [ADDRESS_STATE]: billing.state,
            [ADDRESS_CITY]: billing.city,
            [ADDRESS_POSTCODE]: billing.postcode,
            [ADDRESS_PHONE]: billing.phone,
            [ADDRESS_EMAIL]: billing.email,
        };

        dispatch(setAddressFields(orderFields));
        setOpenAddressModal(true);

        if (mixpanel)
            mixpanel.track('OrderCard Edit Address Open', { ...currentExpoLocation, ...userLocation, ...userData, id });
    }
    function changeOrderAddress() {
        const savedFields = {
            user_location: {
                userData: {id: userData.id, login: userData.login},
                userLocation,
                currentExpoLocation,
            },
            company_meta: fields[ADDRESS_COMPANY_ADDR] ?? '',
            lon: fields[ADDRESS_GEOLON] ?? '',
            lat: fields[ADDRESS_GEOLAT] ?? '',
            first_name: fields[ADDRESS_FIRSTNAME],
            last_name: fields[ADDRESS_LASTNAME],
            company: fields[ADDRESS_COMPANY],
            company_job_title: fields[ADDRESS_COMPANY_JOB_TITLE],
            company_scope: fields[ADDRESS_COMPANY_SCOPE],
            address_1: fields[ADDRESS_ADDRESS],
            address_2: fields[ADDRESS_ADDINFO],
            country: fields[ADDRESS_COUNTRY],
            state: fields[ADDRESS_STATE],
            city: fields[ADDRESS_CITY],
            postcode: fields[ADDRESS_POSTCODE],
            phone: fields[ADDRESS_PHONE],
            email: fields[ADDRESS_EMAIL],
        };

        const query = JSON.stringify({billing: savedFields});
        dispatch(updateOrder(token, id, query));

        setOpenAddressModal(false);

        if (mixpanel)
            mixpanel.track('OrderCard Edit Address Save', { ...currentExpoLocation, ...userLocation, ...userData, id });
    }

    let componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function createOrderBadge() {

       const formObj = {
            user_location: JSON.stringify({
                    userData: {id: userData.id, login: userData.login},
                    userLocation,
                    currentExpoLocation,
                }),
        }
        dispatch(createOrderQRCode(token, id, formObj));

        setOpenPromptModal(false);
        setOpenBadgeModal(true);

        if (mixpanel)
            mixpanel.track('OrderCard Create QR-code', { ...currentExpoLocation, ...userLocation, ...userData, id });
    }

    const modalBadge = () => (
        <Modal
            className="modal-order-badge"
            closeIcon
            open={openBadgeModal}
            onClose={()=>setOpenBadgeModal(false)}
            onOpen={()=>setOpenBadgeModal(true)}
            dimmer
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
        >
            <Header icon="id badge" content={`Бейдж для заказа №${id}`} />
            <Modal.Content>
                <Dimmer active={ [1, id].includes(getLoading) } inverted>
                    <Loader inverted />
                </Dimmer>
                <Card className="order-badge" centered color="orange" raised>
                    <Card.Content>
                        <BadgeToPrint order={order} ref={componentRef} />
                    </Card.Content>
                </Card>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={()=>setOpenBadgeModal(false)}>
                    <Icon name='remove' /> Закрыть
                </Button>
                <Button color='green' onClick={handlePrint} disabled={[1, id].includes(getLoading) || getLoadingCountries}>
                    <Icon name='checkmark' /> Печать
                </Button>
            </Modal.Actions>
        </Modal>
    );

    const promptModal = () => (
        <ModalPrompt
            open={openPromptModal}
            headerIcon="question"
            headerText="Подтверждение"
            messageText={
                `При выдаче бейджа будет сформирован новый QR-код, при этом старый будет автоматически аннулирован и пропуск по нему станет не возможен! Действительно ли создать новый бейдж для заказа №${id}?`
            }
            handleYes={createOrderBadge}
            handleNo={() => setOpenPromptModal(false)}
            handleClose={() => setOpenPromptModal(false)}
            handleOpen={() => setOpenPromptModal(true)}
        />
    );

    const modalAddress = () => (
        <Modal
            className="modal-order-edit-address"
            closeIcon
            dimmer
            open={openAddressModal}
            onClose={()=>setOpenAddressModal(false)}
            onOpen={()=>setOpenAddressModal(true)}
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
        >
            <Header icon="address card" content={`Изменение заказа №${id}`} />
            <Modal.Content>
                <AddressCard />
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={()=>setOpenAddressModal(false)}>
                    <Icon name='remove' /> Отмена
                </Button>
                <Button color='green' onClick={changeOrderAddress} disabled={fields.hasErrors}>
                    <Icon name='checkmark' /> Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );

    function triggerFacingMode () {
        if (statePhoto.mode === FACING_MODES.ENVIRONMENT)
            setStatePhoto({...statePhoto, mode: FACING_MODES.USER});
        else
            setStatePhoto({...statePhoto, mode: FACING_MODES.ENVIRONMENT});
    }

    function addOrderPhoto (image)  {
        const random = (Math.random() + 1).toString(10).substring(6);
        const formData = {
            order_id: id,
            fileName: (String(id) + '-' + random),
            comment: statePhoto.tag,
            operation: 'add',
            created_at: String(new Date()),
        };
        dispatch(uploadOrderImage(token, { ...formData, ...userLocation, ...currentExpoLocation}, image));
        setStatePhoto({...statePhoto, hasOpen: false, hasLoaded: false, dataUri: ''});

        if (mixpanel)
            mixpanel.track('OrderCard Add Photo', { ...currentExpoLocation, ...userLocation, ...userData, id });
    }

    const modalCamera = () => (
        <Modal
            closeIcon
            dimmer
            open={ statePhoto.hasOpen }
            centered
            size="fullscreen"
            closeOnDimmerClick={true}
            className="make-photo"
            onOpen={ () => setStatePhoto({...statePhoto, hasOpen: true, hasLoaded: false}) }
            onClose={ () => setStatePhoto({...statePhoto, hasOpen: false, hasLoaded: false, dataUri: ''}) }
        >
            <Header icon="photo" content={"Фотография к заказу №" + id} />
            <Modal.Content className="modal-photo-image">
                {
                    statePhoto.dataUri !== ''
                    ?   <div className="image-preview image-preview-fullscreen">
                            <Image src={statePhoto.dataUri} />
                        </div>
                    :  <Camera
                            onTakePhoto={(dataUri) => setStatePhoto({...statePhoto, dataUri})}
                            isFullscreen={false}
                            isImageMirror={false}
                            imageCompression={0.5}
                            idealFacingMode={statePhoto.mode}
                            onCameraStart={() => setStatePhoto({...statePhoto, hasLoaded: true})}
                            onCameraError={(error) => {
                                toastr.error('Ошибка запуска камеры! ' + String(error));
                                setStatePhoto({...statePhoto, hasOpen: false, hasLoaded: false, dataUri: ''});
                            }}
                         />
                }
                <Dimmer active={ !statePhoto.hasLoaded }>
                    <Loader>Запускаем камеру ...</Loader>
                </Dimmer>
                <Divider hidden className="task-document-modal-divider"/>
                <div className="camera-control-wrapper">
                   {/* <Button
                        icon
                        onClick={triggerFacingMode}
                        floated="left"
                        disabled={statePhoto.dataUri.length > 0}
                    >
                        <Icon name={statePhoto.mode ===  FACING_MODES.ENVIRONMENT? 'image' : 'user'} />
                    </Button>*/}
                    <Input
                        label={{ tag: true, content: 'тег' }}
                        labelPosition="right"
                        placeholder="Введите описание"
                        onChange={ (e) => setStatePhoto({...statePhoto, tag: e.target.value}) }
                        defaultValue={statePhoto.tag}
                        fluid
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    negative
                    onClick={() =>
                        setStatePhoto({...statePhoto, hasOpen: false, hasLoaded: false, dataUri: ''})
                    }>
                    Отмена
                </Button>
                <Button
                    positive
                    disabled={ statePhoto.dataUri === '' }
                    onClick={ () => addOrderPhoto(statePhoto.dataUri) }
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );

    return (
        <div>
            <Card centered color="orange" raised>
                { header ?
                    <div>
                        <OrderCardHeader order={order} actionButtons={false}/>
                        <Dimmer active={ [1, id].includes(getLoading) || getLoadingCountries } inverted>
                            <Loader inverted />
                        </Dimmer>
                    </div>
                 : ''}
                <Card.Content>
                    <Grid>
                        <Grid.Column className="order-mini-card-header-first-col">
                            <OrderImage />
                        </Grid.Column>
                        <Grid.Column className="order-mini-card-header-second-col">
                            {
                                actionButtons ?
                                    <Button
                                        className="order-mini-card-edit-photo"
                                        icon="camera"
                                        color="green"
                                        disabled={order.status === 'cancelled'}
                                        onClick={()=>setStatePhoto({...statePhoto, hasOpen: true, hasLoaded: false, dataUri: '', })}
                                    /> : ''
                            }
                            <Link to={'/orders/' + id} >
                                <Card.Header as="h4" className="order-header-fio-col">
                                    { billing.last_name + ' ' + billing.first_name }<br/>
                                    { billing.company }<br/>
                                    <br/>
                                    { billing.company_job_title }<br/>
                                    { billing.company_scope }<br/>
                                </Card.Header>
                            </Link>
                        </Grid.Column>
                    </Grid>
                    <Divider />
                    <div className="order-mini-card-country" size="small" dangerouslySetInnerHTML={{ __html: `${orderCountry}, ${orderState}` }} />
                    <div  className="order-mini-card-city" size="small" dangerouslySetInnerHTML={{ __html: `${billing.postcode} ${billing.city}` }} />
                    <div  className="order-mini-card-address" size="small" dangerouslySetInnerHTML={{ __html: `${orderAddress} ${billing.address_2}` }} />

                    <Segment color="orange" className="order-mini-card-contacts">
                        <a className="order-mini-card-phone" href={'tel:' + billing.phone}>
                            <Icon name="call" size="large" />
                            {billing.phone}
                        </a>
                        <a className="order-mini-card-mail" href={'mailto:' + billing.email}>
                            <Icon name="mail" size="large" />
                            {billing.email}
                        </a>
                    </Segment>

                </Card.Content>
                <Card.Content extra>
                    <div className="order-card-action-buttons">
                        { actionButtons
                            ?
                            <div>
                                <Button.Group className="mini-order-action-buttons" fluid>
                                    <Button
                                        color="green"
                                        disabled={order.status === 'cancelled' || order.status === 'on-hold'}
                                        onClick={()=> {
                                            order.status === 'completed'
                                                ? setOpenPromptModal(true)
                                                : createOrderBadge();
                                        }}
                                        compact
                                    >
                                        <Icon name='print'/>
                                        Выдать бейдж
                                    </Button>
                                    <Button
                                        color="grey"
                                        onClick={openModalAddress}
                                        disabled={order.status === 'cancelled'}
                                        compact
                                    >
                                        <Icon name='pencil' />
                                        Изменить
                                    </Button>
                                </Button.Group>
                                { promptModal() }
                                { modalBadge() }
                                { modalAddress() }
                                { modalCamera() }
                            </div>
                            : <Link to={'/orders/' + id} >
                                    <Button fluid color="orange">
                                        Подробнее
                                    </Button>
                            </Link> }
                    </div>
                </Card.Content>
            </Card>

        </div>
        );
}