import React, { Component } from 'react';
import ReduxToastr from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Sidebar, Sticky } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router';

import { MixpanelProvider } from 'react-mixpanel-browser';

import { bindActionCreators } from 'redux';
import { isMenuVisible } from './components/NavBottomBar/reducer';
import { closeMenu } from './components/NavBottomBar/actions';
import NavTopBar from './components/NavTopBar';
import NavBottomBar from './components/NavBottomBar';
import SideMenu from './views/SideMenu';

import './App.css';
import { dropToken } from './components/UserLogin/actions';
import { getUserLoggedIn } from './components/UserLogin/reducer';
import {MIXPANEL_API_KEY} from "./config/secrets";

const MIXPANEL_TOKEN = MIXPANEL_API_KEY;

// [OPTIONAL] Custom options to pass to `mixpanel.init()`.
const MIXPANEL_CONFIG = {
  track_pageview: true, // Set to `false` by default
};

/*export const isMobile = () => {
  const nav = navigator.userAgent.toLowerCase();
  return (
      nav.match(/iphone/i) || nav.match(/ipod/i) || nav.match(/ipad/i) || nav.match(/android/i)
  );
};*/

class App extends Component {
  constructor(props) {
    super(props);

    this.hideSidebar = this.hideSidebar.bind(this);
  }

  hideSidebar() {
    const { sideMenuVisible, closeMenu: close } = this.props;
    if (sideMenuVisible) {
      close();
    }
  }

  render() {
    const {
      sideMenuVisible, children, closeMenu: close, dropToken: logout, isLoggedIn,
    } = this.props;
    return (
      <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
      <div>
        <ReduxToastr
          timeOut={4000}
          newestOnTop
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
        <NavTopBar />
        <Sidebar.Pushable style={{ transform: 'none' }}>
          <Sticky>
            <SideMenu
              isVisible={sideMenuVisible}
              closeMenu={close}
              logOut={logout}
              isLoggedIn={isLoggedIn}
            />
          </Sticky>
          <Sidebar.Pusher
            dimmed={sideMenuVisible}
            onClick={this.hideSidebar}
            style={{ minHeight: '100vh' }}
          >
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <NavBottomBar />
      </div>
      </MixpanelProvider>
    );
  }
}

App.propTypes = {
  sideMenuVisible: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  dropToken: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

App.defaultProps = {
  children: null,
};

const mapStateToProps = (state) => ({
  sideMenuVisible: isMenuVisible(state.navbottombar),
  isLoggedIn: getUserLoggedIn(state.userLogin),
});

function mapDispatchToProps(dispatch) {
  return { dispatch, ...bindActionCreators({ dropToken, closeMenu }, dispatch) };
}

export const withRouter = (Component) => {
  const Wrapper = (props) =>{
    const match = { params: useParams() };
    const navigate = useNavigate();
    return <Component
      match={match}
      navigate={navigate}
      {...props}
    />;
  };
  return Wrapper;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
