import React, { Component } from 'react';
import { Form, Radio, Label, Input } from 'semantic-ui-react';
import {
  PAYMENT_OPTION_BANK,
  PAYMENT_OPTION_CASH,
  PAYMENT_OPTION_ONLINE,
  setPaymentMethod
} from './actions';
import {
  SHIPPING_METHOD_OWN,
  SHIPPING_TYPE_LOCAL_PICKUP
} from '../../components/ShippingCard/actions';
import PropTypes from 'prop-types';
import {
  ADDRESS_EMAIL
} from '../../components/AddressCard/actions';

export default class PaymentOptions extends Component {
  constructor(props) {
    super(props);
    let method = props.paymentOption;
    if ( props.paymentOption === PAYMENT_OPTION_CASH &&
      !(props.shippingMethod === SHIPPING_METHOD_OWN.key &&
        props.shippingType === SHIPPING_TYPE_LOCAL_PICKUP) ) {
      method = PAYMENT_OPTION_ONLINE;
      this.setMethod(method);
    }
    this.state = {
      errors: {},
      emailField: this.props.emailField,
      method: method,
    };
  }

  componentDidMount() {
    this.validation();
  }

  handleChange = (e, { value }) => {
    this.setState({method: value});
    this.setMethod( value );
  };

  setMethod = (method) => {
    const { dispatch } = this.props;
    dispatch(setPaymentMethod( method ));
  };

  setField = (e) => {
    const { dispatch } = this.props;
    this.setState({emailField: e.target.value});
    //dispatch(setAddressFields({ key: e.target.name, value: e.target.value }));
    this.validation();
  };

  validation = () => {
      let errors = {};
      const value = this.state.emailField;

      if (value === '') {
        errors[ADDRESS_EMAIL] = 'это поле обязательно для заполнения';
      } else {
        const lastAtPos = value.lastIndexOf('@');
        const lastDotPos = value.lastIndexOf('.');
        if (
          !(
            lastAtPos < lastDotPos && lastAtPos > 0 &&
            value.indexOf('@@') === -1 && lastDotPos > 2 &&
            value.length - lastDotPos > 2
          )
        ) {
          errors[ADDRESS_EMAIL] = 'неверный формат эл. почты';
        }
      }
      this.setState({ errors });

      const { dispatch } = this.props;
  };

  render() {
    return (
      <Form>
        <Form.Field>
          <Radio
            label='Оплата онлайн'
            name='radioGroup'
            value={PAYMENT_OPTION_ONLINE}
            checked={this.state.method === PAYMENT_OPTION_ONLINE}
            onChange={this.handleChange}
            color='blue'
          />
          <Label as='a' className="label-payment-option-inline" image>
            <img alt="" src='https://e-feed.ru/wp-content/plugins/tinkoff-woocommerce/tinkoff/tinkoff.png'/>
          </Label>
          <Label as='a' className="label-payment-option">
            на странице банка картой или через QR-код
          </Label>
        </Form.Field>
        <Form.Field>
          <Radio
            label='Оплата б/нал на банковский счет'
            name='radioGroup'
            value={PAYMENT_OPTION_BANK}
            checked={this.state.method === PAYMENT_OPTION_BANK}
            onChange={this.handleChange}
            color='blue'
          />
          <Label as='a' className="label-payment-option">
            по реквизитам счета или QR-кодом, счет отправим после создания заказа по эл. почте
          </Label>
          { this.state.method === PAYMENT_OPTION_BANK ?
          <React.Fragment>
            <Input
              name={ADDRESS_EMAIL}
              icon='mail'
              iconPosition='left'
              placeholder='введите адрес электронной почты'
              fluid
              transparent
              className='payment-option-email'
              color='blue'
              value={this.state.emailField}
              onChange={this.setField}
            />
            <span className="shipping-field-error">{this.state.errors[ADDRESS_EMAIL]}</span>
          </React.Fragment> : '' }
        </Form.Field>
        { this.props.shippingMethod === SHIPPING_METHOD_OWN.key &&
          this.props.shippingType === SHIPPING_TYPE_LOCAL_PICKUP ?
          <Form.Field>
            <Radio
              label='Оплата при получении'
              name='radioGroup'
              value={PAYMENT_OPTION_CASH}
              checked={this.state.method === PAYMENT_OPTION_CASH}
              onChange={this.handleChange}
              color='blue'
            />
            <Label as='a' className="label-payment-option">
              наличными в пункте выдачи при получении заказа
            </Label>
          </Form.Field>
         : ''}
      </Form>
    );
  }
}

PaymentOptions.propTypes = {
  paymentMethod: PropTypes.string,
  shippingMethod: PropTypes.string,
  shippingType: PropTypes.string,
};
