import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { fetchProducts } from '../Products/actions';
import { getProducts, getProductsFetching, productPropType } from '../Products/reducer';
import ProductDetails from './ProductDetails';
import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { useParams } from 'react-router';
import {getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";

export default function Product(props) {

  const matchParams = useParams();
  const productID = matchParams.productId;
  const dispatch = useDispatch();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const loading = useSelector( (state) => getProductsFetching(state.products));
  const products =  useSelector( (state) => getProducts(state.products));

  const product = products.find((obj) => obj.id === Number(productID),);

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    readProduct();
    if (mixpanel)
      mixpanel.track('Product Card View', { ...currentExpoLocation, ...userLocation, ...userData, product });
    window.scrollTo(0, 0);
  }, []);

  const readProduct = () => {
    dispatch(fetchProducts({ id: productID }));
  };

  if (loading === 1) {
    return (<div><Loader active /></div>);
  }

  if (_.isNil(product)) {
    return <p>Билет не существует</p>;
  }

  return  (<ProductDetails product={product} />);
}


