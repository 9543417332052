import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

import { store, persistor } from './configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
import Products from './views/Products';
import Categories from './views/Categories';
import Product from './views/Product';
import Cart from './views/Cart';
import Search from './views/Search';
import CheckoutStepShipping from './views/Checkout_1_StepShipping';
import CheckoutStepPayment from './views/Checkout_2_StepPayment';

import './index.css';
import AccessChecker from "./views/AccessChecker";
import CheckoutStepConfirm from "./views/Checkout_3_StepConfirm";
import Orders from "./views/Orders";
import Order from "./views/Order";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader active />} persistor={persistor}>
      <HashRouter>
        <App>
          <Routes>
            <Route exact path="/" Component={props => <Home {... props}/>} />
            <Route path="/categories/:categId" Component={props => <Categories {... props}/>} />
            <Route path="/products/:categId" Component={props => <Products {... props}/>} />
            <Route path="/product/:productId" Component={props => <Product {... props}/>} />
            <Route path="/check-access" Component={props => <AccessChecker {... props}/>} />
            <Route path="/search/:search" Component={props => <Search {... props}/>} />
            <Route path="/cart" Component={props => <Cart {... props}/>} />
            <Route path="/checkout-step-shipping" Component={props => <CheckoutStepShipping {... props}/>} />
            <Route path="/checkout-step-payment" Component={props => <CheckoutStepPayment {... props}/>} />
            <Route path="/checkout-step-confirm" Component={props => <CheckoutStepConfirm {... props}/>} />
            <Route path="/orders" Component={props => <Orders {... props}/>} />
            <Route path="/orders/:orderId" Component={props => <Order {... props}/>} />
          </Routes>
        </App>
      </HashRouter>
    </PersistGate>
  </Provider>
);

registerServiceWorker();
