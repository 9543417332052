import React from 'react';
import PropTypes from 'prop-types';
import {Image, Segment} from 'semantic-ui-react';

import './styles.css';

const CircularImage = props => (
  <Image
    circular
    size="small"
    src={ props.src }
    style={{
      width: props.width,
      height: props.height,
    }}
  />
);

CircularImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CircularImage.defaultProps = {
  src: '',
  width: 60,
  height: 60,
};

export default CircularImage;
