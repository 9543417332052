import 'whatwg-fetch';
import config from '../../config/config';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_1PAGE_PRODUCTS = 'RECEIVE_1PAGE_PRODUCTS';
export const PAGE_COUNT = 20;

export const requestProducts = () => ({
  type: REQUEST_PRODUCTS,
});

export const receiveProducts = (products) => ({
  type: RECEIVE_PRODUCTS,
  products,
});

export const receive1PageProducts = (products) => ({
  type: RECEIVE_1PAGE_PRODUCTS,
  products,
});

export const fetchProducts = (params = {}) => (dispatch) => {
  dispatch(requestProducts());

  let url;
  if (params && params.id) {
    url = config.API_PRODUCT_URL + String(params.id);
  } else {
    url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }

  return fetch(url)
    .then((response) => response.json())
    .then((json) => dispatch(params.page === 1 ? receive1PageProducts(json) : receiveProducts(json)))
    .catch(() => {
      dispatch(receiveProducts([]));
    });
};

export const fetchAllProducts = (params = {}) => (dispatch) => {
  dispatch(requestProducts());

  const url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      dispatch(params.page === 1 ? receive1PageProducts(json) : receiveProducts(json));
      // рекурсия пока все не вычитаем
      if (json.length === params.per_page) {
        params.page ++;
        dispatch(fetchAllProducts(params));
      }
    })
    .catch(() => {
      dispatch(receiveProducts([]));
    });
};
