import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { useParams } from 'react-router';
import OrderCard from "../../components/OrderCard";
import {fetchOrders} from "../Orders/actions";
import {getOrders, getPlaceOrderInProgress} from "../Orders/reducer";
import {getAuthToken, getUserData} from "../../components/UserLogin/reducer";
import {Dimmer, Loader} from "semantic-ui-react";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";

export default function Order(props) {

  const matchParams = useParams();
  const orderID = matchParams.orderId;
  const dispatch = useDispatch();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const orders =  useSelector( (state) => getOrders(state.orders));
  const token = useSelector((state) => getAuthToken(state.userLogin));

  const order = orders.find((obj) => obj.id === Number(orderID),);

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    readOrder();
    if (mixpanel)
      mixpanel.track('Order Card View', { ...currentExpoLocation, ...userLocation, ...userData, order });
    window.scrollTo(0, 0);
  }, []);

  const readOrder = () => {
    dispatch(fetchOrders(token,{ id: orderID }));
  };


  if (_.isNil(order)) {
    console.warn('Заказ для отображения не найден', orders, orderID);
    return  (
      <Dimmer active={ true } inverted>
        <Loader inverted> Загружаем заказ ...</Loader>
      </Dimmer>
      );
  }

  return (
      <div>
        <OrderCard order={order} />
      </div>
  );
}


