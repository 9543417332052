import { combineReducers } from 'redux';
import {
  REQUEST_EXPO_LOCATION_DATA, RECEIVE_EXPO_LOCATION_DATA, SET_EXPO_LOCATION_DATA,
} from './actions';

const expoLocations = (state = [], action = 0) => {
  switch (action.type) {
    case RECEIVE_EXPO_LOCATION_DATA:
      return action.data;
    default:
      return state;
  }
};

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case REQUEST_EXPO_LOCATION_DATA:
      return true;
    case RECEIVE_EXPO_LOCATION_DATA:
      return false;
    default:
      return state;
  }
};

const expoCurrentLocation = (state = [], action = 0) => {
  switch (action.type) {
    case SET_EXPO_LOCATION_DATA:
      return action.data;
    default:
      return state;
  }
};

export const getExpoLocations = (state) => state.expoLocations;
export const isExpoLocationFetching = (state) => state.isFetching;
export const getExpoCurrentLocation = (state) => state.expoCurrentLocation;

const expoLocation = combineReducers({
  expoLocations,
  isFetching,
  expoCurrentLocation,
});

export default expoLocation;