import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Icon, Label, Menu,
} from 'semantic-ui-react';
import { openMenu } from './actions';
import { getCart } from '../../views/Cart/reducer';

class NavBottomBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.showSidebar = this.showSidebar.bind(this);
  }

  getCartQuantity() {
    const { cart } = this.props;
    return cart.reduce((quantity, item) => item.quantity + quantity, 0);
  }

  showSidebar(e) {
    const { openMenu: open } = this.props;
    e.stopPropagation();
    open();
  }

  render() {
    const { cart, wishlist } = this.props;
    return (
      <Menu
        fluid
        secondary
        className="nav-bottom-bar"
        fixed="bottom"
        color="orange"
        icon="labeled"
        inverted
      >
        <Link to="/" className="shop-link">
          <Menu.Item className="shop-icon-item">
            <Icon name="ticket" size="small" className="shop-icon" />
            Билеты
          </Menu.Item>
        </Link>
        <Link to="/orders" className="shop-link">
          <Menu.Item className="shop-icon-item">
            <Icon name="flag checkered" size="small" className="shop-icon" />
            Посетители
          </Menu.Item>
        </Link>
        <Link to="/cart" className="shop-link">
          <Menu.Item className="shop-icon-item">
            <Icon name="shopping basket" size="small" className="shop-icon" />
            {_.isEmpty(cart) ? null : (
              <Label
                color="orange"
                size="mini"
                floating
                circular
                content={this.getCartQuantity()}
                className="cart-counter"
              />
            )}
            Корзина
          </Menu.Item>
        </Link>
        <Menu.Item className="shop-icon-item" onClick={this.showSidebar}>
          <Icon name="content" size="small" className="shop-icon" />
          Меню
        </Menu.Item>
      </Menu>
    );
  }
}

NavBottomBar.propTypes = {
  openMenu: PropTypes.func.isRequired,
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
  wishlist: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  wishlist: getCart(state.cart),
  cart: getCart(state.cart),
});

export default connect(
  mapStateToProps,
  { openMenu },
)(NavBottomBar);
