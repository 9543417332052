import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Sidebar, Menu, Icon, Header,
} from 'semantic-ui-react';

import './styles.css';
import ExpoLocation from "../../components/ExpoLocation";
import useWindowDimensions from "../../components/WindowDimensions";

function SideMenu(props) {
  const {
    isVisible, closeMenu, logOut, isLoggedIn,
  } = props;

  const { height, width } = useWindowDimensions();

  return (
    <Sidebar
      as={Menu}
      animation="push"
      width="thin"
      visible={isVisible}
      icon="labeled"
      inverted
      color="orange"
      direction="right"
      vertical
      className="menu-side-bar"
    >
      <Header as="h2" inverted>
        Меню
      </Header>
      <Link to="#">
        <Menu.Item name="locations">
          <Icon name="move" size="small" />
          {width} x {height} px
        </Menu.Item>
      </Link>
      <Link to="/check-access" onClick={closeMenu}>
        <Menu.Item name="access">
          <Icon name="point" size="small" />
          Проверка локации
        </Menu.Item>
      </Link>
      <Link to="#" onClick={closeMenu}>
        <Menu.Item name="sharing">
          <Icon name="share" size="small" />
          Поделиться приложением
        </Menu.Item>
      </Link>
      {
        isLoggedIn
          ? (
            <Link to="/" onClick={() => { closeMenu(); logOut(); }}>
              <Menu.Item name="logout">
                <Icon name="log out" size="small" />
                Выйти из учетной записи
              </Menu.Item>
            </Link>
          ) : null
      }
    </Sidebar>
  );
}

SideMenu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default SideMenu;
