import 'whatwg-fetch';
import config, {AUTH_INVALID_TOKEN} from '../../config/config';
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";
import _ from "lodash";
import {removeAllProductsFromCart} from "../Cart/actions";

export const REQUEST_ORDERS = 'REQUEST_ORDERS';
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
export const RECEIVE_1PAGE_ORDERS = 'RECEIVE_1PAGE_ORDERS';
export const ORDERS_PAGE_COUNT = 20;
export const CHECKOUT_INSERT_ORDER = 'CHECKOUT_INSERT_ORDER';
export const CHECKOUT_ORDER_PLACED = 'CHECKOUT_ORDER_PLACED';
export const REQUEST_ORDER_UPDATE = 'REQUEST_ORDER_UPDATE';
export const RECEIVE_ORDER_DATA = 'RECEIVE_ORDER_DATA';

export const checkoutInsertOrder = orderData => ({
  type: CHECKOUT_INSERT_ORDER,
  orderData,
});

export const checkoutOrderPlaced = order => ({
  type: CHECKOUT_ORDER_PLACED,
  order,
});

export const requestOrderUpdate = order_id => ({
  type: REQUEST_ORDER_UPDATE,
  order_id,
});

export const receiveOrderData = order => ({
  type: RECEIVE_ORDER_DATA,
  order,
});

export const requestOrders = () => ({
  type: REQUEST_ORDERS,
});

export const receiveOrders = (orders) => ({
  type: RECEIVE_ORDERS,
  orders,
});

export const receive1PageOrders = (orders) => ({
  type: RECEIVE_1PAGE_ORDERS,
  orders,
});

const process_api_error = (json, dispatch) => {
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
  }
  dispatch(checkoutOrderPlaced({}));
  toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const createOrderQRCode = (token, order_id, formObj) => (dispatch) => {
    dispatch(requestOrderUpdate(order_id));

    const headers = { Authorization: 'Bearer ' + token };
    const url = config.API_CREATE_ORDER_QR_CODE_URL + '?order_id=' + String(order_id);

    const formData = new FormData();
    _.each( formObj, (value, key) => formData.append(String(key), value) );

    return fetch(url, { method: 'post', headers, body: formData, })
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveOrderData(json.data));
                dispatch(checkoutOrderPlaced(json.data));
            } else {
                process_api_error(json, dispatch);
            }
        })
        .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const uploadOrderImage = (token, formObj, dataURI) => (dispatch) => {
  dispatch(requestOrderUpdate(formObj.order_id));

  const headers = { Authorization: 'Bearer ' + token };
  const url = config.API_FILE_UPLOAD_URL;

  const formData = new FormData();
  _.each( formObj, (value, key) => formData.append(String(key), value) );
  formData.append("dataURI", dataURI);

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 200) {
            const query = JSON.stringify({
                image: {
                    file_image: json.data.fileUrl,
                    image_meta: formObj,
                }
            });
            dispatch(updateOrder(token, formObj.order_id, query));
        } else {
            process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const updateOrder = (token, order_id, query) => (dispatch) => {
  dispatch(requestOrderUpdate(order_id));

  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_UPDATE_ORDER_URL + order_id;

  const formData = new FormData();
  formData.append('query', query);

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 200) {
          dispatch(receiveOrderData(json.data));
          dispatch(checkoutOrderPlaced(json.data));
          toastr.success(`Заказ №${json.data.id} изменен`);
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => {
        toastr.error('Ошибка запроса: ' + String(ex))
      });
};

export const putOrder = (token, orderData, funcNavigate, toNavigate) => (dispatch) => {
  dispatch(checkoutInsertOrder(orderData));

  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_CREATE_ORDER_URL;

  const formData = new FormData();
  _.each( orderData, (value, key) => formData.append(String(key), value) );

  return fetch(url, { method: 'post', headers, body: formData, })
      .then((response) => response.json())
      .then((json) => {
          if (json.code === 200) {
            dispatch(checkoutOrderPlaced(json.data));
            dispatch(removeAllProductsFromCart());
            toastr.success(`Заказ №${json.data.id} успешно создан!`);
            funcNavigate(toNavigate);
          } else {
              console.warn(' error while put order', url, orderData,json);
              process_api_error(json, dispatch);
          }
      })
      .catch( (ex) => {
          console.warn(' error while put order', url, orderData, json);
          dispatch(checkoutOrderPlaced({}));
          toastr.error('Ошибка запроса: ' + String(ex));
      });
};

export const fetchOrders = (token, params = {}) => (dispatch) => {
  dispatch(requestOrders());
  const headers = { Authorization: 'Bearer ' + token };
  let url;
  if (params && params.id) {
    url = config.API_ORDER_URL + String(params.id);
  } else {
    url = config.API_ORDERS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }

  return fetch(url, {headers})
    .then((response) => response.json())
    .then((json) => {
        if (json.code === 200) {
          dispatch(params.page === 1 ? receive1PageOrders(json.data) : receiveOrders(json.data))
        } else {
          process_api_error(json, dispatch);
        }
    })
    .catch((ex) => {
      //dispatch(receiveOrders([]));
      toastr.error('Ошибка запроса: ' + String(ex));
    });
};
