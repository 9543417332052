import React from 'react';
import { Button } from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate}  from 'react-router-dom';

import { getShippingMethod, getShippingType } from '../ShippingCard/reducer';

import {
    ADDRESS_ADDINFO,
    ADDRESS_ADDRESS, ADDRESS_CITY,
    ADDRESS_COMPANY, ADDRESS_COMPANY_ADDR, ADDRESS_COMPANY_JOB_TITLE, ADDRESS_COMPANY_SCOPE, ADDRESS_COUNTRY,
    ADDRESS_EMAIL, ADDRESS_FIRSTNAME,
    ADDRESS_GEOLAT,
    ADDRESS_GEOLON, ADDRESS_LASTNAME, ADDRESS_PHONE, ADDRESS_POSTCODE,
    ADDRESS_STATE,
} from '../AddressCard/actions';
import { getAddressFields} from '../AddressCard/reducer';
import {getAuthToken, getUserData} from "../UserLogin/reducer";
import {getLocationData} from "../GeoLocation/reducer";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {getPaymentMethod} from "../../views/Checkout_2_StepPayment/reducer";
import {getCartItems, hasOnlyVirtuals} from "../../views/Cart/reducer";
import {putOrder} from "../../views/Orders/actions";
import {useMixpanel} from "react-mixpanel-browser";

export default function ConfirmOrderButton(props) {

    const dispatch = useDispatch();
    const cart_items = useSelector((state) => getCartItems(state.cart));
    const only_virtuals = useSelector((state) => hasOnlyVirtuals(state.cart));

    const shipping_type = only_virtuals? '' : useSelector((state) => getShippingType(state.shipping));
    const shipping_method = only_virtuals ? '' : useSelector((state) => getShippingMethod(state.shipping));
    const address_fields = useSelector((state) => getAddressFields(state.address));
    const payment_option = useSelector((state) => getPaymentMethod(state.payment));

    const token  = useSelector((state) => getAuthToken(state.userLogin));
    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));

    const mixpanel = useMixpanel();

    const navigate = useNavigate();
    const orderData = {
        user_location: JSON.stringify({
            userData: {id: userData.id, login: userData.login},
            userLocation,
            currentExpoLocation
        }),
        company_meta: JSON.stringify(address_fields[ADDRESS_COMPANY_ADDR]),

        shipping_type,
        shipping_method,
        payment_option,

        create_account: '0',

        lon: address_fields[ADDRESS_GEOLON],
        lat: address_fields[ADDRESS_GEOLAT],

        cart_items: JSON.stringify(cart_items),

        first_name: address_fields[ADDRESS_FIRSTNAME],
        last_name: address_fields[ADDRESS_LASTNAME],
        company: address_fields[ADDRESS_COMPANY],
        company_job_title: address_fields[ADDRESS_COMPANY_JOB_TITLE],
        company_scope: address_fields[ADDRESS_COMPANY_SCOPE],
        address_1: address_fields[ADDRESS_ADDRESS],
        address_2: address_fields[ADDRESS_ADDINFO],
        country: address_fields[ADDRESS_COUNTRY],
        state: address_fields[ADDRESS_STATE],
        city: address_fields[ADDRESS_CITY],
        postcode: address_fields[ADDRESS_POSTCODE],
        phone: address_fields[ADDRESS_PHONE],
        email: address_fields[ADDRESS_EMAIL],
    };

    function handleClick() {
        dispatch(putOrder(token, orderData, navigate, props.pathToRoute));

        if (mixpanel)
            mixpanel.track('Checkout Place Order', { ...currentExpoLocation, ...userLocation, ...userData, payment_option, ...address_fields, cart_items });
    }

    return (
        <Button
            color="green"
            className= {`${props.addClassName} button-confirm`}
            onClick={handleClick}
        >
            {props.buttonText}
        </Button>
    );
}



