import React, {useEffect, useRef} from "react";
import {
    Card, Button, Grid, Image
} from 'semantic-ui-react';
import ReactToPrint, {useReactToPrint} from "react-to-print";
import {connect, useSelector} from "react-redux";
import {getPlaceOrderInProgress} from "../../views/Orders/reducer";
import {getCart} from "../../views/Cart/reducer";
import {openMenu} from "../NavBottomBar/actions";
import {store} from "../../configureStore";
import {getUserData} from "../UserLogin/reducer";
import {getLocationData} from "../GeoLocation/reducer";
import {getExpoCurrentLocation} from "../ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {closeSearch} from "../NavTopBar/actions";

export default function PrintComponent(props) {

    const userData  = useSelector((state) => getUserData(state.userLogin));
    const userLocation  = useSelector((state) => getLocationData(state.userLocation));
    const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
    const mixpanel = useMixpanel();

    useEffect(() => {
        if (mixpanel)
            mixpanel.track('Print View', { ...currentExpoLocation, ...userLocation, ...userData, ...props });
    }, []);

    let componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const getLoading = useSelector((state) => getPlaceOrderInProgress(state.orders));

    return (
            <div className="order-badge">
                <BadgeToPrint order={props.order} ref={componentRef} />
                <button onClick={handlePrint}>Print this out!</button>
            </div>
    );
}

export class BadgeToPrint extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        function kitcut( text, limit) {
            text = text.trim();
            if( text.length <= limit) return text;
            text = text.slice( 0, limit); // тупо отрезать по лимиту
            let lastSpace = text.lastIndexOf(" ");
            if( lastSpace > 0) { // нашлась граница слов, ещё укорачиваем
                text = text.slice(0, lastSpace);
            }
            return text + " ...";
        }

        const {order} = this.props;
        const {billing, qrcode} = order;
        const isEnName = billing.last_name_uc === billing.last_name_en;

        const
            styleImageQR = {
                width:"400px",
                margin:"-1.5em",
            },
            styleFirstCol = {
                width:"66%",
                margin:"0 -1.5em 0 0",
            },
            styleSecondCol = {
                width:"34%",
                margin: (isEnName ? "5em 0 0 -0.5em" : "1.5em 0 0 -0.5em"),
            },
            styleOneCol = {
                width:"100%",
                margin:"0",
            },
            styleName = {
                width:"100%!important",
                fontSize:"15px",
                fontWeight:"bold",
                textAlign:"center",
                color:"black",
                margin:"0 0.25em",
            },
            styleFirstName = {
                width:"100%!important",
                fontSize:"14px",
                fontWeight:"bold",
                textAlign:"center",
                color:"black",
                margin:"0 0.25em 0 0",
            },
            styleCompany = {
                width:"100%!important",
                fontSize: (billing.company.length > 40 ? "10px" : "12px"),
                fontWeight:"400",
                textAlign:"center",
                color:"black",
                margin:"0 0.5em",
                
            },
            styleFirstRow = {
                display: "flex",
            };

        return (
            <div>
                <div className="order-badge-first-row" style={styleFirstRow}>
                    <div className="order-badge-first-col" style={styleFirstCol}>
                        <Image src={qrcode} size="medium" className="qr-code" style={styleImageQR}/>
                    </div>
                    <div className="order-badge-second-col" style={styleSecondCol}>
                        { isEnName
                            ?   <p className="last-name en" style={styleName}>
                                    {billing.last_name_en + ' ' + billing.first_name_en}
                                </p>
                            :   <div>
                                    <p className="last-name uc" style={styleName}>{billing.last_name_uc}</p>
                                    <p className="first-name uc" style={styleFirstName}>{billing.first_name_uc}</p>
                                    <br/>
                                    <p className="last-name en" style={styleName}>{billing.last_name_en}</p>
                                    <p className="first-name en" style={styleFirstName}>{billing.first_name_en}</p>
                                </div>
                        }
                    </div>
                </div>
                <div className="order-badge-second-row">
                    <div className="order-badge-one-col" style={styleOneCol}>
                        {/*<p className="company en" style={styleCompany}>
                            {billing.company_en.slice(0, 40) + (billing.company.length > 40 ? ' ...' : '')}
                        </p>*/}
                        <p className="company uc" style={styleCompany}>
                            {kitcut(billing.company_uc, 90)}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
