import React, {useEffect} from 'react';
import { Header } from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';

import './styles.css';
import CheckoutSteps, {CHECKOUT_STEP_CONFIRM} from '../../components/CheckoutSteps';
import { isSearchVisible } from '../../components/NavTopBar/reducer';

import {closeSearch} from "../../components/NavTopBar/actions";
import {store} from "../../configureStore";
import {getPlacedOrder} from "../Orders/reducer";
import OrderCard from "../../components/OrderCard";

export default function CheckoutStepConfirm(props) {

  const dispatch = useDispatch();
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const placedOrder = useSelector((state) => getPlacedOrder(state.orders));

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
  }, []);

  return (
      <div>
          <Header textAlign="center">Заказ создан!</Header>
          <CheckoutSteps step={CHECKOUT_STEP_CONFIRM} />
          <OrderCard order={placedOrder}/>
      </div>
    );
  }



