import 'whatwg-fetch';
import config, {AUTH_INVALID_TOKEN} from '../../config/config';
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";
import {checkoutOrderPlaced, updateOrder} from "../Orders/actions";

export const REQUEST_SEARCH_PRODUCTS = 'REQUEST_SEARCH_PRODUCTS';
export const RECEIVE_SEARCH_PRODUCTS = 'RECEIVE_SEARCH_PRODUCTS';
export const RESET_SEARCH_PRODUCTS = 'RESET_SEARCH_PRODUCTS';

export const REQUEST_SEARCH_ORDERS = 'REQUEST_SEARCH_ORDERS';
export const RECEIVE_SEARCH_ORDERS = 'RECEIVE_SEARCH_ORDERS';
export const RESET_SEARCH_ORDERS = 'RESET_SEARCH_ORDERS';

export const requestSearchProducts = () => ({
  type: REQUEST_SEARCH_PRODUCTS,
});

export const receiveSearchProducts = (products) => ({
  type: RECEIVE_SEARCH_PRODUCTS,
  products,
});

export const resetSearchProducts = () => ({
  type: RESET_SEARCH_PRODUCTS,
});

export const resetSearchOrders = () => ({
  type: RESET_SEARCH_ORDERS,
});

export const requestSearchOrders = () => ({
  type: REQUEST_SEARCH_ORDERS,
});

export const receiveSearchOrders = (orders) => ({
  type: RECEIVE_SEARCH_ORDERS,
  orders,
});

export const fetchProducts = (params = {}) => (dispatch) => {

  if (params.page === 1)
    dispatch(resetSearchProducts());

  dispatch(requestSearchProducts());

  let url;
  if (params && params.id) {
    url = config.API_PRODUCT_URL + String(params.id);
  } else {
    url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }

  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      dispatch(receiveSearchProducts(json));
    })
    .catch(() => {
      dispatch(receiveSearchProducts([]));
    });
};

const process_api_error = (json, dispatch) => {
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
  }
  dispatch(receiveSearchOrders([]));
  toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchOrders = (token, params = {}) => (dispatch) => {

  if (params.page === 1)
    dispatch(resetSearchOrders());

  dispatch(requestSearchOrders());

  let url;
  const headers = { Authorization: 'Bearer ' + token };

  if (params && params.id) {
    url = config.API_ORDER_URL + String(params.id);
  } else {
    url = config.API_ORDERS_URL
        + '?'
        + Object.keys(params)
            .map((k) => k + '=' + encodeURIComponent(params[k]))
            .join('&');
  }

  return fetch(url, {headers})
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 200) {
          dispatch(receiveSearchOrders(json.data));
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};
