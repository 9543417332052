import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'
import  './styles.css'

export default function ModalPrompt(props) {

    const {open, headerIcon, headerText, messageText, handleYes, handleNo, handleClose, handleOpen} = props;

    return (
        <Modal
            closeIcon
            dimmer
            centered
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            closeOnDimmerClick={true}
            className="modal-prompt"
        >
            <Header icon={headerIcon} content={headerText} />
            <Modal.Content>
                <p>{messageText}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={handleNo}>
                    <Icon name='remove' /> Нет
                </Button>
                <Button color='green' onClick={handleYes}>
                    <Icon name='checkmark' /> Да
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
