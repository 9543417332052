import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Loader, Container } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { fetchProducts, PAGE_COUNT } from './actions';
import {
  getProductsFetching, getProducts, getProductsHasMore,
} from './reducer';
import ProductsList from '../../components/ProductsList';
import { closeSearch } from '../../components/NavTopBar/actions';
import { isSearchVisible } from '../../components/NavTopBar/reducer';
import { useParams } from 'react-router';
import {getUserData} from "../../components/UserLogin/reducer";
import {getLocationData} from "../../components/GeoLocation/reducer";
import {getExpoCurrentLocation} from "../../components/ExpoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";

export default function Products(props) {

  const matchParams = useParams();
  const categId = matchParams.categId;
  const searchVisible = useSelector((state) => isSearchVisible(state.navtopbar));
  const loading = useSelector((state) => getProductsFetching(state.products));
  const products = useSelector((state) => getProducts(state.products, categId));
  const hasMore = useSelector((state) => getProductsHasMore(state.products));
  const dispatch = useDispatch();

  const userData  = useSelector((state) => getUserData(state.userLogin));
  const userLocation  = useSelector((state) => getLocationData(state.userLocation));
  const currentExpoLocation  = useSelector((state) => getExpoCurrentLocation(state.expoLocation));
  const mixpanel = useMixpanel();

  useEffect(() => {
    if (searchVisible) {
      dispatch(closeSearch());
    }
    readProducts(1);
    if (mixpanel)
      mixpanel.track('Products List View', { ...currentExpoLocation, ...userLocation, ...userData, categId });
    window.scrollTo(0, 0);
  }, [categId]);

  const getCategoryName = (categories) =>
    categories.find((category) => Number(category.id) === Number(categId)).name;

  const loadProducts = () => {
    if (hasMore) {
      const nextPage = Math.round(products.length / PAGE_COUNT) + 1;
      readProducts(nextPage);
    }
  };

  const readProducts = (page) => {
    dispatch(fetchProducts({
      category: categId,
      page,
      order: 'asc',
      orderby: 'id',
      per_page: PAGE_COUNT,
    }));
  };

  if (loading === 1 && products.length === 0) {
    return ( <div><Loader active /></div> );
  }

  if (products.length === 0) {
    return ( <Container><p>Товары не найдены.</p></Container> );
  }

  return (
    <InfiniteScroll
      dataLength={products.length}
      next={loadProducts}
      hasMore={hasMore}
      loader={<h3 className="scroll-loader-wrapper">Загрузка ...</h3>}
      endMessage={(
          <p style={{ textAlign: 'center' }}>
            <b>Конец списка</b>
          </p>
      )}
    >
      <ProductsList
        products={_.orderBy(products, ['name'], ['asc'])}
        title="Товары " // {this.getCategoryName(products[0].categories)}
      />
    </InfiniteScroll>
  );

}

