import { combineReducers } from 'redux';
import _ from 'lodash';
import { SET_PAYMENT_METHOD} from './actions';
import config from '../../config/config';

const paymentMethod = (state = 0, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD:
      return action.paymentOption;
    default:
      return state;
  }
};

export const getPaymentMethod = state => state.paymentMethod;

const payment = combineReducers({
  paymentMethod,
});
export default payment;
