import { combineReducers } from 'redux';
import PropTypes from 'prop-types';
import {
  REQUEST_USER_DATA, RECEIVE_USER_DATA, REQUEST_TOKEN, RECEIVE_TOKEN, DROP_TOKEN,
} from './actions';

export const userPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  token: PropTypes.string.isRequired,
});

const userData = (state = 0, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_DATA:
      return state;
    case RECEIVE_USER_DATA:
      return action.data;
    default:
      return state;
  }
};

const isUserDataFetching = (state = 0, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_DATA:
      return 1;
    case RECEIVE_USER_DATA:
      return 0;
    default:
      return state;
  }
};

const userToken = (state = [], action = {}) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return state;
    case DROP_TOKEN:
    case RECEIVE_TOKEN:
      return action.data;
    default:
      return state;
  }
};

const isUserTokenFetching = (state = 0, action = {}) => {
  switch (action.type) {
    case REQUEST_TOKEN:
      return 1;
    case RECEIVE_TOKEN:
      return 0;
    case DROP_TOKEN:
      return state;
    default:
      return state;
  }
};

export const getUserData = (state) => state.userData;
export const getUserDataFetching = (state) => state.isUserDataFetching;
export const getAuthToken = (state) => state.userToken.token;
export const getUserTokenFetching = (state) => state.isUserTokenFetching > 0;
export const getUserLoggedIn = (state) => state.userToken.loggedIn;
export const getUserLoginError = (state) => (state.userToken.message ? state.userToken.message : '');
export const getUserDisplayName = (state) => state.userToken.userDisplayName;

const userLogin = combineReducers({
  userData,
  isUserDataFetching,
  userToken,
  isUserTokenFetching,
});

export default userLogin;
